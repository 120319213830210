import React from "react"
const IndexPage = () => {
  return (
    <div className="bg-myblack h-auto w-full">
      <div
        class="flex flex-wrap text-left font-title text-white antialiased pt-16 pb-32 
      pl-4 md:pl-16  lg:pl-0 xl:pl-0"
      >
        <div class="w-full sm:w-1/2 xs:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <p className="font-bold">Editorial Males Herbes</p>
          <p className="mt-4 font-normal">© {new Date().getFullYear()}</p>
        </div>
        <div class="w-full sm:w-1/2 xs:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-16 md:mt-0">
          <p className="font-bold">Textos legals</p>
          <p className="mt-4 font-normal">Avís legal</p>
          <p className="mt-2 font-normal">Política de cookies</p>
          <p className="mt-2 font-normal">Política de privacitat</p>
          <p className="mt-2 font-normal">Condicions de compra</p>
        </div>
        <div
          class="w-full sm:w-1/2 xs:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4
        mt-20 lg:mt-0 xl:mt-0
        "
        >
          <p className="font-bold">Contacte</p>
          <p className="mt-4 font-normal">info@editorialmalesherbes.com</p>
          <p className="mt-2 font-normal">C/ Guilleries, 26, 1er 2a</p>
          <p className="mt-2 font-normal ">08012 Barcelona</p>
        </div>
        <div class="w-full sm:w-1/2 xs:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-20  lg:mt-0 xl:mt-0">
          <p className="font-bold">Xarxes socials</p>
          <p className="mt-4 font-normal">
            <a href="https://instagram.com/editorialmalesherbes">Instagram</a>
          </p>
          <p className="mt-2 font-normal">
            <a href="https://twitter.com/LesMalesHerbes">Twitter</a>
          </p>
          <p className="mt-2 font-normal">
            <a href="https://www.facebook.com/editorial.malesherbes">
              Facebook
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
