import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { useSpring, a, config } from "react-spring"
import { FiShoppingCart } from "react-icons/fi"
import { useStore } from "../store"

const Navbar = () => {
  const { cart } = useStore(state => state)

  const [open, setOpen] = useState(false)

  const props = useSpring({
    config: config.stiff,

    from: {
      width: "0%",
      height: "0%",
      background: "#7bbe7b",
    },
    to: {
      width: open ? "100%" : "0%",
      height: open ? "100%" : "0%",
      background: open ? "black" : "#7bbe7b",
    },
  })
  return (
    <>
      <nav className="mt-8 mb-10">
        {open && (
          <a.div
            style={props}
            className="bg-myblack absolute left-0 top-0 bg-myblack z-50"
          >
            <ul className="flex flex-col justify-center block text-white mt-2 ">
              <li
                onClick={() => setOpen(!open)}
                className="cursor-pointer flex  justify-end mr-8 mt-8 fill-current 
                text-lg py-2 hover:text-malesherbes text-white"
              >
                <svg
                  className="font-title  h-12 h-12"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Close</title>
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
              </li>
              <li class="mt-6">
                <Link
                  className="font-title text-2xl text-center block py-2 hover:text-malesherbes text-white"
                  to={"/"}
                >
                  Inici
                </Link>
              </li>
              <li class="">
                <Link
                  className="font-title  text-2xl text-center block py-2 hover:text-malesherbes text-white"
                  to={"/editorial"}
                >
                  Editorial
                </Link>
              </li>
              <li class="">
                <Link
                  className="font-title  text-2xl  text-center block py-2  hover:text-malesherbes text-white"
                  to={"/cataleg"}
                >
                  Catàleg
                </Link>
              </li>
              <li class="">
                <Link
                  className="font-title  text-2xl text-center block py-2 hover:text-malesherbes text-white"
                  to={"/autors"}
                >
                  Autors
                </Link>
              </li>
              <li class="">
                <Link
                  className="font-title  text-2xl text-center block py-2  hover:text-malesherbes text-white"
                  to={"/noticies"}
                >
                  Notícies
                </Link>
              </li>
              <li class="">
                <p className="font-title text-2xl text-center block py-2 text-gray-500">
                  Revista
                </p>
              </li>

              <li class="">
                <Link
                  className="font-title  text-2xl text-center block py-2 hover:text-malesherbes text-white"
                  to={"/contacte"}
                >
                  Contacte
                </Link>
              </li>
              <li>
                <Link
                  className="font-title  text-2xl text-center block py-2 hover:underline text-white"
                  to={"/botiga"}
                >
                  {" "}
                  {cart.length > 0 && (
                    <span className="mr-2 font-medium text-malesherbes">
                      {cart.length}
                    </span>
                  )}
                  Botiga
                </Link>
              </li>
            </ul>
          </a.div>
        )}
        <div class="block lg:hidden flex justify-center -mt-4 -mb-5">
          <button
            onClick={() => setOpen(!open)}
            class=" object-right items-center px-3 py-2 hover:text-malesherbes hover:border-malesherbes"
          >
            <svg
              class="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <ul className="flex-wrap justify-around hidden lg:flex ">
          <li class="">
            <Link
              className="font-title text-xl text-center block py-2 hover:text-malesherbes text-black"
              to={"/"}
              activeClassName="text-malesherbes"
            >
              Inici
            </Link>
          </li>
          <li class="">
            <Link
              className="font-title text-xl text-center block py-2 hover:text-malesherbes text-black"
              to={"/editorial"}
              activeClassName="text-malesherbes"
            >
              Editorial
            </Link>
          </li>
          <li class="">
            <Link
              className="font-title text-xl text-center block py-2  hover:text-malesherbes text-black"
              to={"/cataleg"}
              activeClassName="text-malesherbes"
            >
              Catàleg
            </Link>
          </li>
          <li class="">
            <Link
              className="font-title text-xl text-center block py-2 hover:text-malesherbes text-black"
              to={"/autors"}
              activeClassName="text-malesherbes"
            >
              Autors
            </Link>
          </li>
          <li class="">
            <Link
              className="font-title text-xl text-center block py-2  hover:text-malesherbes text-black"
              to={"/noticies"}
              activeClassName="text-malesherbes"
            >
              Notícies
            </Link>
          </li>
          <li class="">
            <p className="font-title text-xl text-center block py-2 text-gray-500">
              Revista
            </p>
          </li>

          <li class="">
            <Link
              className="font-title text-xl text-center block py-2 hover:text-malesherbes text-black"
              to={"/contacte"}
              activeClassName="text-malesherbes"
            >
              Contacte
            </Link>
          </li>
          {/* <li class="">
            <Link
              className="font-title text-xl text-left block py-3 hover:text-malesherbes text-black"
              to={"/contacte"}
            >
              <IoIosContact />
            </Link>
          </li>*/}
          <li>
            <Link
              className="font-title text-xl text-left block  py-3 hover:text-malesherbes text-black"
              to={"/botiga"}
              activeClassName="text-malesherbes"
            >
              {" "}
              <div className="flex">
                {cart.length > 0 && (
                  <span className="mr-2 text-malesherbes">{cart.length}</span>
                )}
                <FiShoppingCart className="mt-1" />
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Navbar
