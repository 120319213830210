import React from "react"
import { Link } from "gatsby"
import Navbar from "./Navbar"
import Footer from "./footer"
import logo from "../../static/logo.svg"
import { useStore } from "../store"
import SimpleReactLightbox from 'simple-react-lightbox'

const Layout = ({ children, place }) => {
  const { cart } = useStore(state => state)

  return (
    <SimpleReactLightbox>
      <div className="w-full h-auto font-title antialiased text-base p-2 xs:p-2 sm:p-2 md:p-0 xl:p-0 lg:p-0">
        <div className="mx-auto w-full">
          <Link to={"/"}>
            <img src={logo} className="block m-auto mt-20 mb-6" />
          </Link>
        </div>
        <div className="container xl mx-auto">
          <Navbar />
          {children}
        </div>
      </div>
      <div className="bg-myblack mt-12 p-0 xs:p-2 sm:p-2 md:p-0 xl:p-0 lg:p-0">
        <div className="container mx-auto">
          <Footer />
        </div>
      </div>
      {cart.length > 0 && (
        <Link to={"/botiga"}>
          <div
            className={`${
              place === "botiga" ? `hidden` : `block`
            } md:hidden sticky h-12 font-title cursor-pointer hover:underline p-2 antialiased font-medium uppercase absolute bottom-0  text-center left-0 bg-malesherbes z-50`}
          >
            <span className="text-lg">{cart.length} / Comprar</span>
          </div>
        </Link>
      )}
    </SimpleReactLightbox>
  )
}

export default Layout
