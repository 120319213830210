import create from "zustand"

const [useStore, api] = create((set, get) => {
  return {
    searchName: "",
    sortDesc: false,
    tag: "",
    cart: [],
    payment: false,
    formData: null,
    paymentError: false,
    llistat: false,
    setLlistat: () => set({ llistat: !get().llistat }),
    saveFormData: data => set({ formData: data }),
    actions: {
      onChange: text => set(state => ({ searchName: text.target.value })),
      setTag: tag => set(state => ({ tag })),
      onSorting: () => set(state => ({ sortDesc: !get().sortDesc })),
      addBookToCart: book => set({ cart: [...get().cart, book] }),
      duplicateBookFromCart: book => set({ cart: [...get().cart, book] }),
      removeBookFromCart: book =>
        set({ cart: get().cart.filter(b => b.id !== book.id) }),
      clearCart: () => set({ cart: [] }),
    },
  }
})

export { useStore, api }
